import axios from '@/lib/drupal/api/axiosInstance';
import GamesModel, { DrupalGameData, ExtendedGameData, GameData } from '@/lib/drupal/models/Games';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';

export default class DailyGamesModel {
  static async getAllGames (_limit: number, _offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/daily-games/block/all-daily-games');
    } catch (error) {
      logErrorEvent('Get All Daily Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getTopGames (_limit: number, _offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/daily-games/block/top-daily-games');
    } catch (error) {
      logErrorEvent('Get Top Daily Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
  }
  
  static async getTokenGames (_limit: number, _offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/daily-games/block/token-games');
    } catch (error) {
      logErrorEvent('Get Daily Token Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
  }
  
  static async getTrophyGames (_limit: number, _offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/daily-games/block/trophy-games');
    } catch (error) {
      logErrorEvent('Get Daily Trophy Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
  }
  
  static async getFeaturedGames (_limit: number, _offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/featured-daily-games');
    } catch (error) {
      logErrorEvent('Get Daily Featured Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async featuredGamesFetcher (): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get('/api/featured-daily-games');
    } catch (error) {
      logErrorEvent('Get Featured Daily Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }
}
